import * as React from 'react';
import { graphql } from 'gatsby';
import cn from 'classnames';
import { selectors } from 'store';
import { useGlobal } from './hooks';
import { Price } from './Price';
import CartOrangeIcon from './icons/CartOrange';
import CartWhiteIcon from './icons/CartWhite';

type Props = {
  readonly data: GatsbyTypes.CartItemCounter_DataFragment;
  readonly className?: string;
};

const controlCn =
  'flex items-center justify-center w-9 h-9 text-mf-orange text-2xl leading-none border border-mf-orange rounded-md select-none	transition-colors duration-300 hover:text-white hover:bg-mf-orange';

export const CartItemCounter = ({ data, className }: Props) => {
  const [quantity, globalActions] = useGlobal((state) => selectors.cart.getQuantityById(state, data.rowId));

  if (data.status === 'BY_REQUEST') {
    return <div className={cn('text-red', className)}>Нет в наличии</div>;
  }

  const q = Math.max(quantity, 1);
  const totalSum = q * data.price;
  const oldTotalSum = q * (data.oldPrice || 0);

  const increase = () => globalActions.cart.increaseQuantity(data);

  const decrease = () => globalActions.cart.decreaseQuantity(data);

  let quantityLabel = `${q} шт`;
  let { unit } = data;

  if ((unit === 'гр' || unit === 'г') && data.weight) {
    const v = Math.round(quantity * data.weight * 100) / 100;
    if (v < 1) {
      quantityLabel = `${Math.round(v * 1000)} г`;
    } else {
      unit = 'кг';
    }
  }

  if (unit === 'кг' && data.weight) {
    const v = Math.round(quantity * data.weight * 100) / 100;
    quantityLabel = `${v} кг`;
  }

  const controlDisabled = data.maxQuantityPerOrder ? q >= data.maxQuantityPerOrder : false;

  let controls = (
    <div className="flex items-center justify-between w-32">
      <button type="button" onClick={decrease} className={controlCn}>
        –
      </button>
      <div className="text-mf-black text-xs font-bold leading-4 whitespace-nowrap mx-1">{quantityLabel}</div>
      <button
        type="button"
        onClick={increase}
        className={cn(controlCn, controlDisabled && 'text-mf-gray-1 border-mf-gray-1 pointer-events-none')}
      >
        +
      </button>
    </div>
  );

  if (quantity === 0) {
    controls = (
      <button
        type="button"
        onClick={increase}
        className="group flex items-center justify-center w-32 h-9 text-mf-orange border border-mf-orange rounded-md text-sm font-bold transition-colors duration-300 hover:text-white hover:bg-mf-orange"
      >
        <div className="relative mr-2">
          <CartOrangeIcon className="transition-opacity duration-300 group-hover:opacity-0" />
          <CartWhiteIcon className="transition-opacity duration-300 absolute top-0 opacity-0 group-hover:opacity-100" />
        </div>
        В корзину
      </button>
    );
  }

  return (
    <div className={cn('flex items-center', className)}>
      <div className="text-mf-black flex items-center mr-auto">
        <Price value={totalSum} className="font-bold leading-none" />
        {oldTotalSum > 0 && <Price value={oldTotalSum} className="text-sm line-through leading-none ml-2" />}
      </div>
      {controls}
    </div>
  );
};

export const query = graphql`
  fragment CartItemCounter_data on Api_Product {
    rowId
    price
    oldPrice
    unit
    weight
    status
    maxQuantityPerOrder
    counterInit
    counterStep
  }
`;
