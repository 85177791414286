import * as React from 'react';
import s from './ProductCardSkeleton.module.css';

export const ProductCardSkeleton = () => (
  <>
    <div className={s.image} />
    <div className="h-4 bg-gray-95 rounded mb-2" />
    <div className="h-4 bg-gray-95 rounded mb-3" />
    <div className="flex items-center justify-between">
      <div className="w-10 h-4 bg-gray-95 rounded" />
      <div className={s.button} />
    </div>
  </>
);
