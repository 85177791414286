import * as React from 'react';
import { Link, graphql } from 'gatsby';
import cn from 'classnames';
import { getNameAndWeight } from 'utils';
import { DiscountTag } from './DiscountTag';
import { CartItemCounter } from './CartItemCounter';
import s from './ProductCard.module.css';

type Props = {
  readonly data: GatsbyTypes.ProductCard_DataFragment;
};

export const ProductCard = ({ data }: Props) => {
  const [name, weight] = getNameAndWeight(data.name, data.unit, data.weight);

  return (
    <div className="flex flex-col h-full">
      <Link to={data.pageUrl} className={s.imageWrapper}>
        <img
          src={data.normalImage}
          srcSet={`${data.retinaImage} 2x`}
          alt={name}
          data-sizes="auto"
          className="lazyload absolute left-0 top-0"
        />
        <DiscountTag price={data.price} oldPrice={data.oldPrice} className="absolute right-2 top-2" />
      </Link>
      <Link to={data.pageUrl} className={cn('flex text-mf-black font-bold leading-5', weight ? 'mb-1' : 'mb-4')}>
        {name}
      </Link>
      {weight && <div className="text-mf-gray-3 text-sm leading-5 mb-1">{weight}</div>}
      <CartItemCounter data={data} className="mt-auto" />
    </div>
  );
};

export const query = graphql`
  fragment ProductCard_data on Api_Product {
    normalImage: mainImageUrl(size: 264)
    retinaImage: mainImageUrl(size: 528)
    name
    unit
    weight
    pageUrl
    price
    oldPrice
    ...CartItemCounter_data
  }
`;
