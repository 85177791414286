import * as React from 'react';
import { PageProps } from 'gatsby';
import qs from 'query-string';
import { toGatsbyProduct } from 'monkeypatch';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ProductCardSkeleton } from 'components/ProductCardSkeleton';
import { ProductCard } from 'components/ProductCard';
import { useProductsPageQuery } from '__generated__/urql';

type WrapperProps = {
  readonly title: string;
};

const Wrapper: React.FC<WrapperProps> = ({ title, children }) => (
  <Layout>
    <SEO title={title} />
    <div className="pc-row pb-8">
      <Breadcrumbs />
      <h1 className="text-black text-4xl font-bold leading-snug mb-10">{title}</h1>
      {children}
    </div>
  </Layout>
);

const ProductsPage = ({ location }: PageProps) => {
  const qp = qs.parse(location.search, { arrayFormat: 'comma' });

  if (!Array.isArray(qp.ids) || typeof qp.title !== 'string') {
    return null;
  }

  const ids = [...new Set(qp.ids)].map((id) => parseInt(id, 10));

  const [res] = useProductsPageQuery({
    variables: { ids },
  });

  if (res.fetching || !res.data) {
    return (
      <Wrapper title={qp.title}>
        <div className="pc-free-items">
          {ids.map((id) => (
            <div className="pc-free-item" key={id}>
              <ProductCardSkeleton />
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }

  if (res.error) {
    return (
      <Wrapper title={qp.title}>
        <div className="text-red">Произошла ошибка</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper title={qp.title}>
      <div className="pc-free-items">
        {res.data.productsByIds.map((p) => (
          <div className="pc-free-item" key={p.rowId}>
            <ProductCard data={toGatsbyProduct(p)} />
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export const query = /* urqlGraphQL */ `
  query ProductsPage($ids: [Int!]!) {
    productsByIds(ids: $ids) {
      rowId
      ...ProductCard_data
    }
  }
`;

export default ProductsPage;
